<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">
          <!-- Bar Chart - Orders -->
          <b-col lg="12" md="6">
            <b-card class="card-transaction" no-body>
              <b-card-header>
                <b-card-title>Image renseigné</b-card-title>
              </b-card-header>

              <b-card-body>
                <img v-if="imageB64 !== ''" :src="imageB64" alt="Image renseigné dans le formulaire" />
                <span v-else>Aucune image renseignée !</span>
              </b-card-body>
            </b-card>
          </b-col>
          <b-col lg="12" md="6" v-if="data !== ''">
            <b-card class="card-transaction" no-body>
              <b-card-header>
                <b-card-title>Données déchiffrées</b-card-title>
              </b-card-header>

              <b-card-body>

                <p class="text-center" style="background: darkgreen; border-radius: 10px; padding: 1em;" >{{ this.data }}</p>

              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-col>

      <b-col lg="8">
        <b-card no-body>
          <b-card-header>
            <b-card-title>Formulaire de déchiffrement</b-card-title>
            <b-card-sub-title>Vous pouvez télécharger le QRCode dans l'onglet "Vos nœuds"</b-card-sub-title>
          </b-card-header>

          <b-card-body>
            <div>
              <b-form @submit.prevent="onSubmit">
                <b-form-group>
                  <b-form-file
                      v-model="file"
                      @input="onFileChange"
                      accept="image/*"
                      placeholder="Sélectionner l'image que vous voulez déchiffrer"
                  ></b-form-file>
                </b-form-group>
                <b-form-group>
                  <b-form-input
                      class="mt-1"
                      v-model="key"
                      type="text"
                      placeholder="Entrez du texte ici..."
                  ></b-form-input>
                </b-form-group>
                <b-button
                    class="mt-1"
                    type="submit"
                    :disabled="isLoading"
                ><span v-if="!isLoading">Déchiffrer</span><span v-if="isLoading">Chargement...</span></b-button>
              </b-form>

            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>

    <b-row class="match-height">
    </b-row>
  </section>
</template>

<script>
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
  BTable,
  BBadge,
  BFormFile,
  BCardHeader,
  BMediaBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BButton,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormCheckbox, BForm,
} from "bootstrap-vue";
import axios from "axios";

import VueApexCharts from "vue-apexcharts";
import moment from "moment";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BCardHeader,
    BForm,
    BFormGroup,
    BButton,
    BFormFile,
    BFormInput
  },
  data() {
    return {
      data: '',
      file: null,
      key: '',
      imageB64: '',
      isLoading: false
    };
  },
  methods: {
    onSubmit(e) {
      this.isLoading = true;
      e.preventDefault();
      if (this.key === '' || this.imageB64 === '') {
        return;
      }
      axios.post(`https://apisb.openlogs.fr/api/decrypt`, {
        _base64: this.imageB64.split(',')[1],
        _key: this.key
      }).then(({ data }) => {
        console.log(data);
        this.data = `${data.data.decryptedData}`
        this.isLoading = false;
      }).catch((error) => {
        console.log(error)
        this.isLoading = false;
      });
    },
    onFileChange(file) {
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageB64 = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>

<style lang="scss" scoped>
.transaction-item .media {
  align-items: center;
}
</style>
